import { router, usePage } from "@inertiajs/react";
import { memo, useContext, useEffect, useState } from "react";

import BB_Button from "@/Components/BB_Button";
import BB_CommandPalette from "@/Components/BB_CommandPalette";
import BB_ProfileMenu from "@/Components/BB_ProfileMenu";
import BB_SideBar from "@/Components/BB_SideBar";
import { DriverTourContext } from "@/ContextProviders/DriverTour";
import { SizeContext } from "@/ContextProviders/SizeProvider";
import { StorageContext } from "@/ContextProviders/StorageProvider";
import BarsIcon from "@/Icons/bars.svg?react";
import BellIcon from "@/Icons/bell.svg?react";
import LogoLarge from "@/Icons/logo_large.svg?react";
import MagnifyingGlassIcon from "@/Icons/magnifyingGlass.svg?react";
import RegisterIcon from "@/Icons/register.svg?react";
import SigninIcon from "@/Icons/signin.svg?react";
import XMarkIcon from "@/Icons/xmark.svg?react";
import BB_User from "@/types/BB_User";

export type BB_NavBarProps = {
	hideButtons?: boolean;
};

const BB_NavBar = (props: BB_NavBarProps) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [searchOpen, setSearchOpen] = useState(false);
	const [profileOpen, setProfileOpen] = useState(false);

	const { fancyAnimations } = useContext(StorageContext);
	const driver = useContext(DriverTourContext);
	const user: BB_User | undefined = usePage().props.auth?.user;

	const { isMedium } = useContext(SizeContext);

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if ((e.metaKey || e.ctrlKey) && e.key === "p") {
				e.preventDefault();
				setSearchOpen((prev) => !prev);
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, []);

	const showSearch = () => {
		driver?.searchMiniTour.current?.destroy();
		setSearchOpen((prev) => !prev);

		// manually focus the input after short transition animation / portal render delay
		setTimeout(() => {
			if (typeof document !== "undefined") {
				document.getElementById("searchField")?.focus();
			}
		}, 100);
	};

	return (
		<div className={"select-none"}>
			<div
				className={`fixed top-0 z-30 w-full ${
					fancyAnimations
						? "bg-background-100/60 dark:bg-dark-background-300/80 backdrop-blur-lg"
						: "bg-background-50 dark:bg-dark-background-300"
				} sm:shadow-lg`}>
				<div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-6">
					<div className="flex h-16 justify-between">
						<div className="order-1 flex items-center">
							<button
								aria-label="Öffne Hauptmenü"
								aria-expanded={menuOpen}
								aria-controls="Hauptmenü"
								className="hover:bg-background-50 dark:hover:bg-dark-background-400 ripple-effect ripple-background-200 dark:ripple-dark-background-500 inline-flex cursor-pointer items-center justify-center rounded-md p-2 select-none dark:text-white"
								onClick={() => {
									setMenuOpen((prev) => !prev);
								}}>
								{menuOpen ? (
									<XMarkIcon className="pointer-events-none block h-6 w-6" />
								) : (
									<BarsIcon className="pointer-events-none block h-6 w-6" />
								)}
							</button>
						</div>

						<div className="order-2 ml-2 flex items-center px-2">
							<div className="flex shrink-0 flex-row items-center">
								<BB_Button
									type="plain"
									onClick="/"
									ripple={false}
									icon={LogoLarge}
									iconClassName="block h-8 w-auto"
									alt="Startseite"
								/>
							</div>
						</div>

						<div className="order-3 hidden flex-1 items-center justify-center px-2 sm:flex lg:ml-3 lg:justify-normal">
							<div className="w-full max-w-sm lg:max-w-sm">
								<div className="relative">
									<button
										id="openSearch1"
										onClick={showSearch}
										className={`bg-background-50 dark:bg-dark-background-400 text-primary-500 dark:text-dark-primary-500 flex w-full cursor-text items-center rounded-md border-0 py-1.5 pr-3 pl-3 shadow-lg placeholder:text-gray-400 sm:text-sm sm:leading-6 ${
											searchOpen ? "hidden" : ""
										}`}>
										<MagnifyingGlassIcon className="text-primary-500 h-5 w-5 dark:text-white" />
										<span className="ml-2 truncate text-gray-400">
											Produkte durchsuchen
										</span>
									</button>
								</div>
							</div>
						</div>

						<div className="order-3 ms-auto mr-2 flex items-center justify-center sm:hidden">
							<BB_Button
								id="openSearch2"
								type="plain"
								onClick={showSearch}
								icon={MagnifyingGlassIcon}
								iconClassName="h-5 w-5 text-primary-500! dark:text-white!"
								buttonClassName={`flex items-center justify-center p-2.5 rounded-md text-primary-500 dark:text-dark-primary-500 hover:bg-background-50 dark:hover:bg-dark-background-400 ${
									searchOpen ? "hidden" : ""
								}`}
							/>
						</div>

						{props.hideButtons === true ? (
							<></>
						) : (
							<>
								{user ? (
									<div className="order-4 hidden sm:flex">
										<div className="mr-3 flex items-center justify-center overflow-hidden">
											<BB_Button
												type="plain"
												title={`${isMedium ? "" : "Alarme verwalten"}`}
												icon={BellIcon}
												iconClassName={
													"h-5 w-5 text-black! dark:text-white! md:mr-1"
												}
												buttonClassName={`flex items-center justify-center p-2.5 rounded-md text-black! dark:text-white! hover:bg-background-50 dark:hover:bg-dark-background-400 leading-[1.3] ${
													route().current("manage.alarms") &&
													"dark:bg-dark-background-200 hover:dark:bg-dark-background-200 pointer-events-none"
												}`}
												onClick={() => router.visit(route("manage.alarms"))}
											/>
										</div>
										<div className="mr-3 flex items-center justify-center overflow-hidden">
											<BB_Button
												type="profile"
												icon={SigninIcon}
												buttonClassName="w-[36px] h-[36px]"
												iconClassName={"h-5 w-5 pointer-events-none"}
												onClick={() => setProfileOpen((prev) => !prev)}
											/>
										</div>
									</div>
								) : (
									<>
										<div className="order-4 ml-2 hidden items-center lg:flex">
											<BB_Button
												type="secondary"
												title="Registrieren"
												icon={RegisterIcon}
												iconClassName={"h-5 w-5 mr-2 pointer-events-none"}
												onClick={route("register")}
											/>
										</div>

										<div className="order-4 mx-2 hidden items-center sm:flex">
											<BB_Button
												type="primary"
												title="Anmelden"
												icon={SigninIcon}
												iconClassName={"h-5 w-5 mr-2 pointer-events-none"}
												onClick={route("login")}
											/>
										</div>
									</>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			<BB_SideBar open={menuOpen} setOpen={setMenuOpen} />

			<BB_CommandPalette open={searchOpen} setOpen={setSearchOpen} />

			{user && <BB_ProfileMenu open={profileOpen} setOpen={setProfileOpen} />}
		</div>
	);
};

export default memo(BB_NavBar);
