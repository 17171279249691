import React, { memo, useContext } from "react";

import BB_AnimatedModal from "@/Components/BB_AnimatedModal";
import BB_Button from "@/Components/BB_Button";
import { FirebaseContext } from "@/ContextProviders/FirebaseProvider";
import { ModalContext } from "@/ContextProviders/ModalProvider";
import SpinnerIcon from "@/Icons/spinner.svg?react";

const PopupWaitingModal = () => {
	const { requestToken } = useContext(FirebaseContext);
	const { showPopupWaitingModal, setShowPopupWaitingModal } = useContext(ModalContext);

	const handleRetryPermission = async () => {
		if ((await Notification.requestPermission()) === "granted") {
			requestToken();
			setShowPopupWaitingModal(false);
		}
		setShowPopupWaitingModal(false);
	};

	return (
		<BB_AnimatedModal
			isOpen={showPopupWaitingModal}
			setIsOpen={setShowPopupWaitingModal}
			disableClose={true}>
			<h2 className="mt-6 text-xl font-semibold text-gray-900 dark:text-white">
				<SpinnerIcon className="fill-primary-500 mr-3 inline h-5 w-5 animate-spin text-gray-200 dark:text-gray-600" />
				Erlaube Benachrichtigungen
			</h2>
			<p className="mt-4 text-gray-700 dark:text-gray-300">
				Bitte klicke auf <strong>&quot;Zulassen&quot;</strong> im Berechtigungs-Popup deines
				Browsers (oben links), um unsere Web-Benachrichtigungen zu aktivieren.
			</p>
			{/*
				<div className="mt-6 flex justify-center">
					<LogoLarge
						className="max-h-20 max-w-xs rounded-lg shadow-md"
					/>
				</div>
			*/}
			<div className="mt-8 space-y-4 md:flex md:gap-4 md:space-y-0">
				<BB_Button
					type="secondary"
					onClick={() => setShowPopupWaitingModal(false)}
					buttonClassName="flex-1 w-full bg-gray-500 hover:bg-gray-600"
					title="Später erinnern"
				/>
				<BB_Button
					type="primary"
					onClick={handleRetryPermission}
					buttonClassName="flex-1 w-full"
					title="Erneut anfragen"
				/>
			</div>
		</BB_AnimatedModal>
	);
};

export default memo(PopupWaitingModal);
