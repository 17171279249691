import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { Link, usePage } from "@inertiajs/react";
import { Fragment, memo, useContext, useEffect, useState } from "react";

import { SizeContext } from "@/ContextProviders/SizeProvider";
import BellIcon from "@/Icons/bell.svg?react";
import ExploreIcon from "@/Icons/compass.svg?react";
import DealsIcon from "@/Icons/deals.svg?react";
import HomeIcon from "@/Icons/home.svg?react";
import SignoutIcon from "@/Icons/logout.svg?react";
import { BB_SVG } from "@/types/BB_SVG";
import BB_User from "@/types/BB_User";

export type BB_SideBarProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
};

export type BB_Solutions = {
	name: string;
	description: string;
	href: string;
	icon: BB_SVG;
	red?: boolean;
};

const BB_SideBar = (props: BB_SideBarProps) => {
	const user: BB_User | undefined = usePage().props.auth?.user;
	const [solutions, setSolutions] = useState<BB_Solutions[]>([]);
	const { isCompact } = useContext(SizeContext);

	useEffect(() => {
		if (user) {
			setSolutions([
				{
					name: "Homefeed",
					description: "Updates zu deinen Produkten",
					href: route("feed"),
					icon: HomeIcon
				},
				{
					name: "Produkte entdecken",
					description: "Entdecke Produkte nach Kategorien",
					href: route("explore"),
					icon: ExploreIcon
				},
				{
					name: "Alarme verwalten",
					description: "Manage deine aktiven Alarme",
					href: route("manage.alarms"),
					icon: BellIcon
				},
				{
					name: "Deals",
					description: "Günstige Angebote bei Foraum",
					href: "https://www.foraum.de",
					icon: DealsIcon
				}
			]);
		} else {
			setSolutions([
				{
					name: "Startseite",
					description: "Hier findest du beliebte Produkte",
					href: route("home"),
					icon: HomeIcon
				},
				{
					name: "Produkte entdecken",
					description: "Entdecke Produkte nach Kategorien",
					href: route("explore"),
					icon: ExploreIcon
				},
				{
					name: "Deals",
					description: "Günstige Angebote bei Foraum",
					href: "https://www.foraum.de",
					icon: DealsIcon
				}
			]);
		}
	}, [user]);

	const logoutItem = {
		name: "Abmelden",
		description: "Melde dich von deinem Account ab",
		href: route("logout"),
		icon: SignoutIcon,
		red: true
	};

	const ButtonWrapper = ({
		href,
		children,
		...rest
	}: {
		href: string;
		children: React.ReactNode;
		className: string;
	}) => {
		return href.startsWith("bestell.bar") ||
			href.startsWith("www.bestell.bar") ||
			href.startsWith("https://www.bestell.bar") ||
			href.startsWith("/") ? (
			<Link href={href} {...rest}>
				{children}
			</Link>
		) : (
			<a href={href} {...rest} target="_blank" rel="noopener noreferrer">
				{children}
			</a>
		);
	};

	const renderSolution = (item: BB_Solutions) => {
		return (
			<div
				key={item.name}
				className="group dark:hover:bg-dark-background-400 relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
				<div className="bg-background-50 dark:bg-dark-background-400 dark:group-hover:bg-dark-background-300 mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg group-hover:bg-white">
					<item.icon
						className={`h-6 w-6 ${item.red ? "text-red-500!" : "text-gray-600 dark:text-gray-400"} group-hover:text-primary-500`}
						alt="Link Icon"
						title="Link Icon"
						draggable="false"
					/>
				</div>
				<div>
					<ButtonWrapper
						href={item.href}
						className={`font-semibold ${item.red ? "text-red-500!" : "text-gray-900! dark:text-white!"}`}>
						{item.name}
						<span className="absolute inset-0" />
					</ButtonWrapper>
					<p className="mt-1 text-gray-600 dark:text-gray-400">{item.description}</p>
				</div>
			</div>
		);
	};

	return (
		<Transition show={props.open} as={Fragment} appear>
			<Dialog
				as="div"
				className="relative"
				onClose={() => {
					setTimeout(() => {
						props.setOpen(false);
					}, 100);
				}}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-40 dark:opacity-50"
					leave="ease-in duration-200"
					leaveFrom="opacity-40 dark:opacity-50"
					leaveTo="opacity-0">
					<div className="fixed inset-0 z-20 bg-black/40 backdrop-blur-xs transition-opacity dark:bg-black/50" />
				</TransitionChild>

				<div className="fixed top-0 left-0 z-30 mt-16 ml-0 w-full max-w-screen overflow-y-auto sm:mt-20 sm:ml-4 sm:max-w-md lg:max-w-3xl 2xl:left-[max(0px,(50%-48rem))]">
					<TransitionChild
						as={Fragment}
						enter={
							isCompact
								? "ease-out duration-300 transform opacity-0 -translate-y-10"
								: "ease-out duration-300 opacity-0 scale-95"
						}
						enterFrom={isCompact ? "opacity-0 -translate-y-10" : "opacity-0 scale-95"}
						enterTo={isCompact ? "opacity-100 translate-y-0" : "opacity-100 scale-100"}
						leave={
							isCompact
								? "ease-in duration-200 transform opacity-0 -translate-y-10"
								: "ease-in duration-200 opacity-0 scale-95"
						}
						leaveFrom={
							isCompact ? "opacity-100 translate-y-0" : "opacity-100 scale-100"
						}
						leaveTo={isCompact ? "opacity-0 -translate-y-10" : "opacity-0 scale-95"}>
						<DialogPanel className="rounded-0 bg-background-100 dark:bg-dark-background-300 mb-10 max-h-screen flex-auto overflow-auto text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 sm:rounded-lg md:mb-0">
							<div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
								{solutions.map((item) => renderSolution(item))}
								{isCompact && user && renderSolution(logoutItem)}
							</div>
							<div className="bg-background-200 dark:bg-dark-background-200 px-8 py-6 shadow-lg">
								<div className="flex items-center gap-x-3">
									<h3 className="text-sm leading-6 font-semibold text-gray-900 dark:text-white">
										Bestell.bar App
									</h3>
									<p className="bg-primary-500/10 text-primary-500 rounded-full px-2.5 py-1.5 text-xs font-semibold">
										Bald verfügbar
									</p>
								</div>
								<p className="mt-2 text-sm leading-6 text-pretty text-gray-600 dark:text-gray-400">
									Echtzeit-Benachrichtigungen direkt auf dein Device!
								</p>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</Dialog>
		</Transition>
	);
};

export default memo(BB_SideBar);
