import { memo, useContext, useEffect } from "react";

import BB_Background from "@/Components/BB_Background";
import BB_FloatingButton from "@/Components/BB_FloatingButton";
import BB_Footer from "@/Components/BB_Footer";
import BB_NavBar from "@/Components/BB_NavBar";
import BB_Notification from "@/Components/BB_Notification";
import BB_TabBar from "@/Components/BB_TabBar";
import { LocationContext } from "@/ContextProviders/LocationProvider";
import BB_AvailabilityModal from "@/Modals/AvailabilityModal";
import BB_PopupBlockedModal from "@/Modals/PopupBlockedModal";
import BB_PopupNewTabModal from "@/Modals/PopupNewTabModal";
import PopupWaitingModal from "@/Modals/PopupWaitingModal";

type GuestProps = {
	children?: React.ReactNode;
	hideButtons?: boolean;
};

const Guest = (props: GuestProps) => {
	const { location } = useContext(LocationContext);

	useEffect(() => {
		if (location && ["/", "/feed"].includes(location.pathname)) {
			document.querySelectorAll(".bell-waist, .bell-clapper, .bell-ring").forEach((el) => {
				(el as HTMLElement).style.animationPlayState = "running";
			});
		}
	}, [location]);

	return (
		<>
			<BB_NavBar hideButtons={props.hideButtons} />
			<BB_Background />

			<main className="mt-16 pb-8">
				<div className="mx-auto max-w-3xl px-0 sm:py-8 md:px-6 lg:max-w-7xl lg:px-8">
					{props.children}
				</div>
			</main>

			<BB_Footer />
			<BB_TabBar isLoggedIn={false} />
			<BB_Notification />

			<BB_FloatingButton />

			<BB_AvailabilityModal />
			<BB_PopupBlockedModal />
			<BB_PopupNewTabModal />
			<PopupWaitingModal />
		</>
	);
};

export default memo(Guest);
