import { Link } from "@inertiajs/react";
import { memo, useContext } from "react";

import { DriverTourContext } from "@/ContextProviders/DriverTour";
import { StorageContext } from "@/ContextProviders/StorageProvider";
import BellIcon from "@/Icons/bell.svg?react";
import ActiveBellIcon from "@/Icons/bell_active.svg?react";
import ExploreIcon from "@/Icons/compass.svg?react";
import ActiveExploreIcon from "@/Icons/compass_active.svg?react";
import HomeIcon from "@/Icons/home.svg?react";
import ActiveHomeIcon from "@/Icons/home_active.svg?react";
import ProfileIcon from "@/Icons/profile.svg?react";
import ActiveProfileIcon from "@/Icons/profile_active.svg?react";
import RegisterIcon from "@/Icons/register.svg?react";
import ActiveRegisterIcon from "@/Icons/register_active.svg?react";
import SignInIcon from "@/Icons/signin.svg?react";
import ActiveSignInIcon from "@/Icons/signin_active.svg?react";

export type BB_TabProps = {
	isLoggedIn: boolean;
};

const BB_TabBar = (props: BB_TabProps) => {
	const { fancyAnimations } = useContext(StorageContext);
	const { showTabs } = useContext(DriverTourContext);

	const authenticatedTabs = [
		{
			name: "Start",
			routeName: "feed",
			href: route("feed"),
			icons: [HomeIcon, ActiveHomeIcon]
		},
		{
			name: "Entdecken",
			routeName: "explore",
			href: route("explore"),
			icons: [ExploreIcon, ActiveExploreIcon]
		},
		{
			name: "Alarme",
			routeName: "manage.alarms",
			href: route("manage.alarms"),
			icons: [BellIcon, ActiveBellIcon]
		},
		{
			name: "Profil",
			routeName: "settings",
			href: route("settings"),
			icons: [ProfileIcon, ActiveProfileIcon]
		}
	];

	const guestTabs = [
		{
			name: "Start",
			routeName: "home",
			href: route("home"),
			icons: [HomeIcon, ActiveHomeIcon]
		},
		{
			name: "Entdecken",
			routeName: "explore",
			href: route("explore"),
			icons: [ExploreIcon, ActiveExploreIcon]
		},
		{
			name: "Registrieren",
			routeName: "register",
			href: route("register"),
			icons: [RegisterIcon, ActiveRegisterIcon]
		},
		{
			name: "Anmelden",
			routeName: "login",
			href: route("login"),
			icons: [SignInIcon, ActiveSignInIcon]
		}
	];

	const tabs = props.isLoggedIn ? authenticatedTabs : guestTabs;

	if (!showTabs) return null;

	return (
		<div
			className={`fixed right-0 bottom-0 left-0 z-20 ${fancyAnimations ? "bg-background-100/60 dark:bg-dark-background-300/80 backdrop-blur-lg" : "bg-background-50 dark:bg-dark-background-300"} flex items-center justify-evenly border-t border-transparent pt-2 pb-[env(safe-area-inset-bottom)] sm:hidden dark:border-transparent`}>
			{tabs.map(({ name, routeName, href, icons }, index) => {
				const isActive = route().current(routeName);
				const TabIcon = isActive ? icons[1] : icons[0];

				return (
					<Link
						key={routeName + "_" + index.toString()}
						href={href}
						className={`flex flex-1 flex-col items-center pb-2 ${isActive ? "text-primary-500!" : "text-black! dark:text-white!"}`}>
						<TabIcon className="h-6 w-6" />
						<span className="mt-1 text-xs">{name}</span>
					</Link>
				);
			})}
		</div>
	);
};

export default memo(BB_TabBar);
