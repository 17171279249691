import React, { memo, ReactNode } from "react";

import XMarkIcon from "@/Icons/xmark.svg?react";

export type BB_AnimatedModalProps = {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	disableClose?: boolean;
	children: ReactNode;
};

const BB_AnimatedModal = (props: BB_AnimatedModalProps) => {
	return (
		<>
			{props.isOpen && (
				<div
					onClick={() => {
						if (props.disableClose === false) {
							props.setIsOpen(false);
						}
					}}
					className="fixed inset-0 z-60 grid place-items-center overflow-y-auto bg-black/40 backdrop-blur-xs dark:bg-black/50">
					<div
						onClick={(e: { stopPropagation: () => void }) => e.stopPropagation()}
						className="dark:bg-dark-background-300 relative w-full max-w-2xl overflow-hidden rounded-none bg-white shadow-lg ring-1 ring-gray-900/5 sm:rounded-2xl">
						{!props.disableClose && (
							<button
								onClick={() => props.setIsOpen(false)}
								className="absolute top-6 right-6 rounded-full bg-slate-900/50 p-2 hover:bg-slate-900/75">
								<XMarkIcon className="h-4 w-4" />
							</button>
						)}

						<div className="max-h-[calc(100vh)] overflow-y-auto md:max-h-[calc(100vh-4rem)]">
							<div className="my-6 px-6">{props.children}</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default memo(BB_AnimatedModal);
