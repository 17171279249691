import React, { memo, useContext } from "react";

import BB_AnimatedModal from "@/Components/BB_AnimatedModal";
import { ModalContext } from "@/ContextProviders/ModalProvider";

const AvailabilityModal = () => {
	const { showAvailabilityModal, setShowAvailabilityModal } = useContext(ModalContext);

	return (
		<BB_AnimatedModal isOpen={showAvailabilityModal} setIsOpen={setShowAvailabilityModal}>
			<h1>Availability Modal</h1>
		</BB_AnimatedModal>
	);
};

export default memo(AvailabilityModal);
