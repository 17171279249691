import { usePage } from "@inertiajs/react";
import { memo, useEffect } from "react";
import { Slide, toast, ToastContainer } from "react-toastify";

import SuccessIcon from "@/Icons/checkmarkCircle.svg?react";
import InfoIcon from "@/Icons/info.svg?react";
import AlertIcon from "@/Icons/warning.svg?react";
import XMarkIcon from "@/Icons/xmark.svg?react";

const BB_Notification = () => {
	const pageProps = usePage();

	const shouldShowFlash = () => {
		if (pageProps.props?.session?.flash !== undefined) {
			toast(pageProps.props.session.flash, {
				type: "info",
				toastId: pageProps.props.session.flash,
				position: "top-right",
				autoClose: 7000,
				closeButton: false,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				transition: Slide,
				onClick: () => {
					toast.dismiss(pageProps.props.session.flash);
				}
			});
		}
	};

	useEffect(() => {
		shouldShowFlash();
	}, [pageProps.props?.session?.flash]);

	return (
		<ToastContainer
			stacked
			position="top-right"
			autoClose={6000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="dark"
			transition={Slide}
			icon={({ type }) => {
				switch (type) {
					case "error":
						return <XMarkIcon className="stroke-red-500" />;
					case "success":
						return <SuccessIcon className="stroke-green-500" />;
					case "warning":
						return <AlertIcon className="stroke-yellow-500" />;
					default:
						return <InfoIcon className="stroke-primary-500" />;
				}
			}}
		/>
	);
};

export default memo(BB_Notification);
