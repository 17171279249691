import {
	Combobox,
	ComboboxInput,
	ComboboxOption,
	ComboboxOptions,
	Dialog,
	DialogPanel,
	Transition,
	TransitionChild
} from "@headlessui/react";
import { router } from "@inertiajs/react";
import axios from "axios";
import { Fragment, memo, useContext, useEffect, useState } from "react";

import BB_Button from "@/Components/BB_Button";
import { BB_Link } from "@/Components/BB_Footer";
import BB_ImageWrapper from "@/Components/BB_ImageWrapper";
import { ModalContext } from "@/ContextProviders/ModalProvider";
import { SearchContext } from "@/ContextProviders/SearchProvider";
import { SizeContext } from "@/ContextProviders/SizeProvider";
import ChevronRightIcon from "@/Icons/chevron_right.svg?react";
import LinkIcon from "@/Icons/link.svg?react";
import MagnifyingGlassIcon from "@/Icons/magnifyingGlass.svg?react";
import SpinnerIcon from "@/Icons/spinner.svg?react";
import WarningIcon from "@/Icons/warning.svg?react";
import BB_Product from "@/types/BB_Product";

export type BB_CommandPaletteProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
};

const linkKeywords: Record<string, string[]> = {
	foraum: ["foraum", "forum", "community"],
	heise: ["heise", "news", "tech"],
	datenschutz: ["datenschutz", "privacy", "gdpr"],
	nutzungsbedingungen: ["nutzungsbedingungen", "agb"],
	impressum: ["impressum", "imprint"],
	tutorial: ["tutorial", "anleitungen", "guide"]
	//faq: ["faq", "hilfe", "support"],
	//testimonials: ["testimonials", "bewertungen", "feedback"],
	//kontakt: ["kontakt", "contact", "support"],
};

const BB_CommandPalette = (props: BB_CommandPaletteProps) => {
	const { isCompact } = useContext(SizeContext);
	const { openInNewTab } = useContext(ModalContext);

	const staticLinks: BB_Link[] = [
		{
			id: "foraum",
			name: "Foraum.de",
			href: "https://foraum.de",
			external: true
		},
		{
			id: "heise",
			name: "Heise.de",
			href: "https://heise.de",
			external: true
		},
		{
			id: "datenschutz",
			name: "Datenschutz",
			href: route("static", { page: "privacy" })
		},
		{
			id: "impressum",
			name: "Impressum",
			href: route("static", { page: "imprint" })
		},
		{
			id: "nutzungsbedingungen",
			name: "Nutzungsbedingungen",
			href: route("static", { page: "tos" })
		},
		{
			id: "tutorial",
			name: "Tutorial",
			href:
				route("p", { b_id: "99UR", slug: "dualsense-wireless-controller" }) + "?tutorial=1"
		}
	];

	const [isLoading, setIsLoading] = useState(false);
	const [query, setQuery] = useState("");
	const [results, setResults] = useState<(BB_Product | BB_Link)[]>([]);
	const { searchHistory, addSearchTerm } = useContext(SearchContext);

	useEffect(() => {
		setIsLoading(true);
		const handler = setTimeout(async () => {
			if (!query || query.length < 3) {
				setResults([]);
				setIsLoading(false);
				return;
			}

			try {
				const searchTerm = query.toLowerCase().trim();
				const response = await axios.get(`/api/s?q=${searchTerm}`);
				const apiResults: BB_Product[] = response.data.data;

				const filteredLinks = staticLinks.filter((link) =>
					linkKeywords[link.id]?.some((keyword) => keyword.includes(searchTerm))
				);

				setResults([...apiResults, ...filteredLinks]);
			} catch (searchError) {
				setResults([]);
				console.error("[🔍] search Error", searchError);
			} finally {
				setIsLoading(false);
			}
		}, 500);

		return () => clearTimeout(handler);
	}, [query]);

	const renderSearchResult = (search: BB_Product | BB_Link) => {
		if (typeof search !== "object" || search === null) {
			return;
		}

		return (
			<ComboboxOption
				key={search.id}
				value={search}
				className={({ focus }) =>
					`flex cursor-pointer items-center px-4 py-3 text-black select-none dark:text-white ${focus && "bg-background-200 dark:bg-dark-background-200"}`
				}>
				{"image" in search ? (
					<BB_ImageWrapper
						bb_image={search.image}
						className="mx-auto h-10 w-10 overflow-hidden rounded-full bg-white object-contain shadow-md select-none"
						suffix="?class=1"
						alt="Product"
					/>
				) : (
					<LinkIcon
						className={
							"mx-1 h-6 w-8 object-contain text-black select-none dark:text-white"
						}
					/>
				)}

				<span className="ml-3 flex flex-auto flex-col gap-1 truncate overflow-visible text-black dark:text-white">
					<span>{search.name}</span>
					{"category" in search && search.category && (
						<BB_Button
							key={search.category.id}
							type="plain"
							title={search.category.name}
							buttonClassName="w-fit px-3 py-1 rounded-full text-xs font-semibold text-gray-700! dark:text-gray-200! bg-background-50 dark:bg-dark-background-400 hover:bg-background-100 dark:hover:bg-dark-background-350 shadow-md"
							onClick={route("explore", {
								b_id: search.category.b_id,
								slug: search.category.slug
							})}
						/>
					)}
				</span>
				<ChevronRightIcon className="ml-auto h-4 w-4 text-gray-400 select-none" />
			</ComboboxOption>
		);
	};

	const navigate = (item: BB_Product | BB_Link) => {
		if (!item) {
			return;
		} else if ("b_id" in item) {
			router.visit(
				route("p", {
					b_id: item.b_id,
					slug: item.slug
				})
			);
		} else if (item.external) {
			openInNewTab(item.href);
		} else {
			router.visit(item.href);
		}

		// add to search history
		addSearchTerm(item);
	};

	const showSearchResults =
		query.length === 0 && searchHistory.length > 0 && results.length === 0;

	return (
		<Transition show={props.open} as={Fragment} afterLeave={() => setQuery("")} appear>
			<Dialog as="div" className="relative" onClose={() => props.setOpen(false)}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-40 dark:opacity-50"
					leave="ease-in duration-200"
					leaveFrom="opacity-40 dark:opacity-50"
					leaveTo="opacity-0">
					<div className="fixed inset-0 z-30 bg-black/40 backdrop-blur-xs dark:bg-black/50" />
				</TransitionChild>

				<div className="fixed inset-0 z-30 mt-0 overflow-y-auto sm:mt-15 sm:p-6 md:mt-0 md:p-20">
					<TransitionChild
						as={Fragment}
						enter={
							isCompact
								? "ease-out duration-300 transform opacity-0 -translate-y-10"
								: "ease-out duration-300 opacity-0 scale-95"
						}
						enterFrom={isCompact ? "opacity-0 -translate-y-10" : "opacity-0 scale-95"}
						enterTo={isCompact ? "opacity-100 translate-y-0" : "opacity-100 scale-100"}
						leave={
							isCompact
								? "ease-in duration-200 transform opacity-0 -translate-y-10"
								: "ease-in duration-200 opacity-0 scale-95"
						}
						leaveFrom={
							isCompact ? "opacity-100 translate-y-0" : "opacity-100 scale-100"
						}
						leaveTo={isCompact ? "opacity-0 -translate-y-10" : "opacity-0 scale-95"}>
						<DialogPanel className="bg-background-100 dark:bg-dark-background-300 mx-auto max-w-full transform divide-y divide-gray-100 overflow-hidden rounded-none shadow-lg transition-all sm:rounded-lg lg:max-w-xl dark:divide-gray-500">
							<Combobox
								onClose={() => {
									props.setOpen(false);
								}}
								onChange={navigate}>
								<div className="relative">
									<MagnifyingGlassIcon
										className="pointer-events-none absolute top-0 left-4 h-16 w-5 text-black md:h-12 dark:text-white"
										aria-hidden="true"
									/>
									<ComboboxInput
										autoFocus
										type="search"
										id="searchField"
										name="searchField"
										className="h-16 w-full border-0 bg-transparent pr-4 pl-11 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm md:h-12 dark:text-white"
										placeholder="Produkt suchen"
										onChange={(event) => setQuery(event.target.value)}
										onKeyDown={(e) => {
											// on enter press
											// if no results and no search history is shown
											// -> prevent default
											if (
												e.key === "Enter" &&
												results.length === 0 &&
												!showSearchResults
											) {
												e.preventDefault();
											}
										}}
									/>
								</div>

								{showSearchResults && (
									<ComboboxOptions
										static
										className="max-h-96 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto">
										<ul className="text-sm text-gray-700">
											{searchHistory.map((item) => renderSearchResult(item))}
										</ul>
									</ComboboxOptions>
								)}

								{!isLoading && results.length > 0 && (
									<ComboboxOptions
										static
										className="max-h-96 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto">
										<ul className="text-sm text-gray-700">
											{results.map((item) => renderSearchResult(item))}
										</ul>
									</ComboboxOptions>
								)}

								{isLoading && query.length > 2 && (
									<div className="px-6 py-14 text-center text-sm sm:px-14">
										<SpinnerIcon
											className="fill-primary-500 pointer-events-none mx-auto h-6 w-6 animate-spin text-gray-200 dark:text-gray-600"
											aria-hidden="true"
										/>
									</div>
								)}

								{!isLoading && query.length > 2 && results.length === 0 && (
									<div className="px-6 py-14 text-center text-sm sm:px-14">
										<WarningIcon
											className="text-primary-500 dark:text-dark-primary-500 mx-auto h-6 w-6"
											aria-hidden="true"
										/>
										<p className="mt-4 font-semibold text-black dark:text-white">
											Nichts gefunden
										</p>
										<p className="mt-2 text-gray-400">
											Versuch es mit einem anderen Suchbegriff oder
											Schlüsselwort.
										</p>
									</div>
								)}

								<div className="hidden flex-wrap items-center px-4 py-2.5 text-xs text-gray-400 sm:flex">
									{"Kurzbefehl: "}
									<div className="mx-1 flex items-center">
										<kbd className="bg-primary-500 dark:bg-dark-primary-500 flex h-5 w-5 items-center justify-center rounded border font-semibold text-black sm:mx-2">
											<span className="text-md">⌘</span>
										</kbd>
										<span className="text-md font-bold">{" / "}</span>
										<kbd className="bg-primary-500 dark:bg-dark-primary-500 flex h-5 w-10 items-center justify-center rounded border font-semibold text-black sm:mx-2">
											<span className="text-md">Strg</span>{" "}
											{/* Strg für Windows */}
										</kbd>
										<span className="text-md font-bold">{" + "}</span>
										<kbd className="bg-primary-500 dark:bg-dark-primary-500 flex h-5 w-5 items-center justify-center rounded border font-semibold text-black sm:mx-2">
											<span className="text-md">P</span>
										</kbd>
									</div>
								</div>
							</Combobox>
						</DialogPanel>
					</TransitionChild>
				</div>
			</Dialog>
		</Transition>
	);
};

export default memo(BB_CommandPalette);
