import { Button } from "@headlessui/react";
import { Link } from "@inertiajs/react";
import { memo } from "react";

import { BB_SVG } from "@/types/BB_SVG";

export type BB_ButtonProps = {
	id?: string;
	title?: string;

	icon?: BB_SVG;
	iconClassName?: string;

	disabled?: boolean;
	ripple?: boolean;
	type:
		| "primary"
		| "secondary"
		| "plain"
		| "submit"
		| "available"
		| "notify"
		| "unknown"
		| "profile"
		| "destructive"
		| "custom";
	buttonClassName?: string;
	onClick?: string | ((e?: MouseEvent | TouchEvent) => void);
	onMouseEnter?: string | ((e?: MouseEvent | TouchEvent) => void);
	onMouseLeave?: string | ((e?: MouseEvent | TouchEvent) => void);
	alt?: string;
	hideText?: boolean;
};

const BASE_URL = import.meta.env.VITE_APP_URL ?? "https://bestell.bar";

function isSafariBrowser() {
	if (typeof navigator !== "undefined") {
		const userAgent = navigator.userAgent;
		return /^((?!chrome|android).)*safari/i.test(userAgent);
	} else {
		return false;
	}
}

const BB_Button = ({ ripple, ...props }: BB_ButtonProps) => {
	const safeRipple = ripple ?? !isSafariBrowser();
	const isLink = typeof props.onClick === "string";

	const getClassNameByType = () => {
		switch (props.type) {
			case "submit":
			case "notify":
			case "primary":
				return `select-none flex flex-row items-center justify-center rounded-md bg-primary-500 dark:bg-dark-primary-400 hover:bg-primary-400 dark:hover:bg-dark-primary-500 px-4 py-2 text-sm font-medium text-background-900! shadow-lg h-[36px] ${
					safeRipple
						? "ripple-effect ripple-primary-700 dark:ripple-dark-primary-300"
						: ""
				}`;
			case "secondary":
				return `select-none flex flex-row items-center justify-center shrink-0 rounded-md bg-background-50 dark:bg-dark-background-400 px-4 py-2 text-sm font-medium text-background-900! dark:text-white! hover:bg-background-100 dark:hover:bg-dark-background-350 shadow-lg h-[36px] ${
					safeRipple
						? "ripple-effect ripple-background-200 dark:ripple-dark-background-500"
						: ""
				}`;
			case "destructive":
				return `select-none flex flex-row items-center justify-center shrink-0 rounded-md bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-400 dark:hover:bg-red-600 shadow-lg h-[36px] ${
					safeRipple ? "ripple-effect ripple-ripplered-500!" : ""
				}`;
			case "available":
				return `select-none flex flex-row items-center justify-center shrink-0 rounded-md text-white! bg-dark-secondary-500 hover:bg-dark-secondary-600 px-4 py-2 text-sm font-medium shadow-lg h-[36px] ${
					safeRipple ? "ripple-effect ripple-dark-secondary-700" : ""
				}`;
			case "unknown":
				return `select-none flex flex-row items-center justify-center shrink-0 rounded-md border border-gray-400 dark:border-gray-500 text-background-400! dark:text-gray-500! bg-background-200 dark:bg-dark-background-200 px-4 py-2 text-sm font-medium shadow-lg h-[36px] ${
					safeRipple
						? "ripple-effect ripple-background-400 dark:ripple-dark-background-300"
						: ""
				}`;
			case "profile":
				return `select-none flex flex-row items-center justify-center rounded-full bg-primary-500 dark:bg-dark-primary-500 hover:bg-primary-600 dark:hover:bg-dark-primary-600 p-2 text-sm font-medium text-background-900! shadow-lg h-[36px] ${
					safeRipple
						? "ripple-effect ripple-primary-700 dark:ripple-dark-primary-300"
						: ""
				}`;
			default:
				return "";
		}
	};

	const ButtonContent = (
		<>
			{props.icon && (
				<props.icon
					className={"pointer-events-none " + props.iconClassName}
					title={props.alt}
					alt={props.alt}
					draggable="false"
				/>
			)}
			<span className={`pointer-events-none ${props.hideText ? "hidden lg:flex" : ""}`}>
				{props.title}
			</span>
		</>
	);

	if (isLink) {
		const link = props.onClick?.toString() ?? "";
		if (link.startsWith(BASE_URL) || link.startsWith("/")) {
			return (
				<Link
					id={props.id}
					className={`cursor-pointer ${props.buttonClassName} ${getClassNameByType()}`}
					href={props.onClick as string}>
					{ButtonContent}
				</Link>
			);
		} else {
			return (
				<a
					id={props.id}
					className={`cursor-pointer ${props.buttonClassName} ${getClassNameByType()}`}
					href={props.onClick as string}
					target={"_blank"}
					rel="noopener nofollow noreferrer">
					{ButtonContent}
				</a>
			);
		}
	} else {
		return (
			<Button
				id={props.id}
				type={props.type === "submit" ? "submit" : "button"}
				className={`${(props.disabled ?? false) ? "cursor-not-allowed" : "cursor-pointer"} ${props.buttonClassName} ${getClassNameByType()} `}
				onClick={
					!props.type || props.type !== "submit"
						? (props.onClick as () => void)
						: undefined
				}
				disabled={props.disabled}
				draggable={false}
				onDragStart={(e) => e.preventDefault()}
				onCopy={(e) => e.preventDefault()}>
				{ButtonContent}
			</Button>
		);
	}
};

export default memo(BB_Button);
