import React, { memo, useContext } from "react";

import BB_AnimatedModal from "@/Components/BB_AnimatedModal";
import { ModalContext } from "@/ContextProviders/ModalProvider";

const PopupNewTabModal = () => {
	const { showPopupBlockedModal, setShowPopupBlockedModal } = useContext(ModalContext);

	return (
		<BB_AnimatedModal isOpen={showPopupBlockedModal} setIsOpen={setShowPopupBlockedModal}>
			<h2 className="mt-6 text-xl font-semibold text-gray-900 dark:text-white">
				⚠️ Neuer Tab blockiert
			</h2>
			<p className="mt-6 text-pretty hyphens-auto text-gray-700 dark:text-gray-300">
				Es sieht so aus, als hätte dein Browser das Öffnen eines neuen Tabs verhindert.
				Damit Bestell.bar korrekt funktioniert, solltest du das Blockieren von Pop-ups und
				neuen Tabs für unsere Seite deaktivieren.
			</p>
			<div className="mt-8 space-y-2 text-pretty hyphens-auto">
				<h3 className="text-lg font-medium text-gray-900 dark:text-white">
					So deaktivierst du die Blockierung:
				</h3>
				<ol className="list-decimal space-y-2 pl-6 text-gray-700 dark:text-gray-300">
					<li>
						Klicke in der Adressleiste deines Browsers auf das Symbol für blockierte
						Pop-ups (meist ein kleines Fenster mit einem roten X).
					</li>
					<li>
						Wähle &quot;Pop-ups und Weiterleitungen für diese Seite immer
						zulassen&quot;.
					</li>
					<li>Lade die Seite neu, damit die Änderungen wirksam werden.</li>
				</ol>
			</div>
		</BB_AnimatedModal>
	);
};

export default memo(PopupNewTabModal);
