import React, { memo, useContext } from "react";

import BB_AnimatedModal from "@/Components/BB_AnimatedModal";
import { ModalContext } from "@/ContextProviders/ModalProvider";

const PopupBlockedModal = () => {
	const { showPopupBlockedModal, setShowPopupBlockedModal } = useContext(ModalContext);

	return (
		<BB_AnimatedModal isOpen={showPopupBlockedModal} setIsOpen={setShowPopupBlockedModal}>
			<h2 className="mt-6 text-xl font-semibold text-gray-900 dark:text-white">
				😢 Benachrichtigungen Blockiert
			</h2>
			<p className="mt-6 text-pretty hyphens-auto text-gray-700 dark:text-gray-300">
				Es scheint, dass du Web-Benachrichtigungen für Bestell.bar in deinem Browser
				blockiert hast. Du musst sie erneut aktivieren, um Benachrichtigungen von uns zu
				erhalten.
			</p>
			<div className="mt-8 space-y-2 text-pretty hyphens-auto">
				<h3 className="text-lg font-medium text-gray-900 dark:text-white">
					So aktivierst du Benachrichtigungen wieder:
				</h3>
				<ol className="list-decimal space-y-2 pl-6 text-gray-700 dark:text-gray-300">
					<li>
						Öffne die Einstellungen deines Browsers und gehe zu &quot;Datenschutz &
						Sicherheit.&quot;
					</li>
					<li>
						Suche den Abschnitt &quot;Benachrichtigungen&quot; unter
						&quot;Website-Einstellungen.&quot;
					</li>
					<li>Finde den Eintrag für diese Seite und aktiviere die Benachrichtigungen.</li>
				</ol>
			</div>
		</BB_AnimatedModal>
	);
};

export default memo(PopupBlockedModal);
