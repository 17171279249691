import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { router } from "@inertiajs/react";
import { Fragment, memo } from "react";

import SignoutIcon from "@/Icons/logout.svg?react";
import SettingsIcon from "@/Icons/settings.svg?react";

export type BB_ProfileMenuProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
};

const BB_ProfileMenu = (props: BB_ProfileMenuProps) => {
	const items = [
		{
			name: "Einstellungen",
			onClick: () => router.visit(route("settings")),
			icon: SettingsIcon
		},
		{
			name: "Abmelden",
			onClick: () => router.visit(route("logout")),
			icon: SignoutIcon,
			isRed: true
		}
	];

	return (
		<Transition show={props.open} as={Fragment} appear>
			<Dialog
				as="div"
				className="relative"
				onClose={() => {
					setTimeout(() => {
						props.setOpen(false);
					}, 100);
				}}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-40 dark:opacity-50"
					leave="ease-in duration-200"
					leaveFrom="opacity-40 dark:opacity-50"
					leaveTo="opacity-0">
					<div className="fixed inset-0 z-20 bg-black/40 backdrop-blur-xs transition-opacity dark:bg-black/50" />
				</TransitionChild>

				<div className="dark:bg-dark-background-300 fixed top-0 right-[max(0px,(50%-48rem))] z-30 mt-20 mr-4 w-48 rounded-lg bg-white p-2 shadow-lg ring-1 ring-gray-900/5">
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95">
						<DialogPanel>
							{items.map(({ name, onClick, icon: Icon, isRed }) => (
								<button
									key={name}
									onClick={() => {
										props.setOpen(false);
										onClick();
									}}
									className={`dark:hover:bg-dark-background-400 flex w-full cursor-pointer items-center gap-3 rounded-md px-4 py-2 transition hover:bg-gray-100 ${isRed ? "text-red-500 hover:bg-red-100 dark:hover:bg-red-900" : "text-gray-900 dark:text-white"}`}>
									<Icon className="h-5 w-5" />
									{name}
								</button>
							))}
						</DialogPanel>
					</TransitionChild>
				</div>
			</Dialog>
		</Transition>
	);
};

export default memo(BB_ProfileMenu);
