import { memo, useContext, useEffect, useState } from "react";

import { SizeContext } from "@/ContextProviders/SizeProvider";
import { StorageContext } from "@/ContextProviders/StorageProvider";

type MousePos = {
	x: number;
	y: number;
};

const BB_Background = () => {
	const { fancyAnimations } = useContext(StorageContext);
	const { isCompact } = useContext(SizeContext);
	const [mousePos, setMousePos] = useState<MousePos | null>(null);

	useEffect(() => {
		if (!fancyAnimations) return;

		const updateMousePos = (e: { clientX: number; clientY: number }) => {
			setMousePos({ x: e.clientX, y: e.clientY });
		};

		if (typeof window !== "undefined") {
			window.addEventListener("mousemove", updateMousePos);
			return () => window.removeEventListener("mousemove", updateMousePos);
		}
	}, [fancyAnimations]);

	let bgStyle;

	if (fancyAnimations) {
		if (!isCompact && mousePos) {
			bgStyle = {
				maskImage: `radial-gradient(circle 300px at ${mousePos.x}px ${mousePos.y}px, #FFFFFFEE, #00000030)`,
				WebkitMaskImage: `radial-gradient(circle 300px at ${mousePos.x}px ${mousePos.y}px, #FFFFFFEE, #00000030)`,
				backgroundImage: `radial-gradient(circle,#737373EE 1px,transparent 1px)`
			};
		} else {
			bgStyle = {
				backgroundImage: `radial-gradient(circle,#73737330 1px,transparent 1px)`
			};
		}
	} else {
		bgStyle = {
			backgroundImage: `radial-gradient(circle,#73737330 1px,transparent 1px)`
		};
	}

	return <div className={`fixed inset-0 -z-10 bg-[size:20px_20px]`} style={bgStyle} />;
};

export default memo(BB_Background);
