import { memo, useState } from "react";

import BB_Image from "@/types/BB_Image";

interface BB_ImageWrapperProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	bb_image?: BB_Image | null;
	defaultSrc?: string;
	suffix?: string;
	svgClassNames?: string;
	addPadding?: boolean;
	isLazy?: boolean;
}

const defaultImage = import.meta.env.VITE_BB_IMAGE_DEFAULT_URL ?? "/images/default_image.svg";

const BB_ImageWrapper = ({
	bb_image,
	defaultSrc = defaultImage,
	isLazy = true,
	...props
}: BB_ImageWrapperProps) => {
	const [isSvg, setIsSvg] = useState(bb_image?.url?.endsWith(".svg") ?? false);
	const [src, setSrc] = useState(getImageSrc());
	const { className, svgClassNames, addPadding, ...rest } = props;

	function getImageSrc() {
		if (!bb_image?.url) {
			return defaultSrc;
		} else if (isSvg) {
			return bb_image.url;
		} else {
			return `${bb_image.url}${props.suffix ?? ""}`;
		}
	}

	return (
		<img
			src={src}
			width={bb_image?.width}
			height={bb_image?.height}
			loading={isLazy ? "lazy" : "eager"}
			alt={props.alt ?? "Platzhalter Bild"}
			draggable={false}
			onError={() => {
				setSrc(defaultSrc);
				setIsSvg(false);
			}}
			onContextMenu={(e) => e.preventDefault()}
			onDragStart={(e) => e.preventDefault()}
			onCopy={(e) => e.preventDefault()}
			referrerPolicy="no-referrer"
			crossOrigin="anonymous"
			className={`${addPadding && "p-[8px]"} ${className} ${isSvg && svgClassNames}`}
			{...rest}
		/>
	);
};

export default memo(BB_ImageWrapper);
