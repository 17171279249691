import { Link } from "@inertiajs/react";
import { memo } from "react";

import LogoLarge from "@/Icons/logo_large.svg?react";
import TelegramIcon from "@/Icons/telegram.svg?react";
import WhatsappIcon from "@/Icons/whatsapp.svg?react";
import XIcon from "@/Icons/x.svg?react";
import { BB_SVG } from "@/types/BB_SVG";

export type BB_Link = {
	id: string;
	name: string;
	href: string;
	icon?: BB_SVG;
	external?: boolean;
	target?: string;
};

export type BB_FooterKeys = "favorites" | "links" | "social" | "legal";

const BB_Footer = () => {
	const currentYear = new Date().getFullYear();

	const navigation: Record<BB_FooterKeys, BB_Link[]> = {
		favorites: [
			{
				id: "1",
				name: "Switch 2",
				href: route("p", { b_id: "gqop", slug: "nintendo-switch-2" })
			},
			{
				id: "2",
				name: "RTX 5090",
				href: route("p", { b_id: "92vg", slug: "geforce-rtx-5090" })
			},
			{
				id: "3",
				name: "PS5 Laufwerk",
				href: route("p", { b_id: "08ut", slug: "disc-laufwerk-fur-ps5-konsolen" })
			}
		],
		links: [
			{
				id: "4",
				target: "_self",
				name: "Tutorial",
				href:
					route("p", { b_id: "99UR", slug: "dualsense-wireless-controller" }) +
					"?tutorial=1"
			},
			{ id: "5", target: "_blank", name: "Foraum", href: "https://www.foraum.de" },
			{ id: "6", target: "_blank", name: "Sitemap", href: "/sitemap.xml" }
		],
		social: [
			{
				id: "6",
				name: "X",
				href: "https://x.com/Bestell_bar",
				icon: XIcon
			},
			{
				id: "7",
				name: "Whatsapp",
				href: "https://whatsapp.com/channel/0029VaEailJ6xCSU5ahAan02",
				icon: WhatsappIcon
			},
			{
				id: "8",
				name: "Telegram",
				href: "https://t.me/bestellbar",
				icon: TelegramIcon
			}
		],
		legal: [
			{ id: "12", name: "Impressum", href: route("static", { page: "imprint" }) },
			{ id: "13", name: "Nutzungsbedingungen", href: route("static", { page: "tos" }) },
			{ id: "11", name: "Datenschutz", href: route("static", { page: "privacy" }) }
		]
	};

	return (
		<footer className="bg-background-100 dark:bg-dark-background-300">
			<div className="mx-auto max-w-7xl px-6 pt-16 pb-8 sm:pt-24 lg:px-8 lg:pt-32">
				<div className="xl:grid xl:grid-cols-3 xl:gap-8">
					<div className="space-y-3">
						<LogoLarge className="hidden h-7 w-auto sm:block" />
						<p className="text-secondary-900 dark:text-secondary-100 text-sm leading-6">
							Kein Release mehr verpassen.
						</p>
						<div className="flex space-x-6">
							{navigation.social.map((item) => (
								<a
									key={item.id}
									href={item.href}
									className="text-primary-500! hover:text-black! hover:dark:text-white!"
									target="_blank"
									rel="noopener noreferrer">
									{item.icon && (
										<item.icon
											className="h-6 w-6"
											draggable="false"
											title="Soziale Medien"
											alt="Soziale Medien"
										/>
									)}
								</a>
							))}
						</div>
					</div>
					<div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
						<div className="md:grid md:grid-cols-2 md:gap-8">
							<div>
								<h3 className="text-secondary-900 dark:text-secondary-100 text-sm leading-6 font-semibold">
									Favoriten
								</h3>
								<ul className="mt-6 space-y-4">
									{navigation.favorites.map((item) => (
										<li key={item.id}>
											<Link
												href={item.href}
												className="hover:text-primary-500! text-sm leading-6 text-gray-400! dark:text-gray-400!">
												{item.name}
											</Link>
										</li>
									))}
								</ul>
							</div>
							<div className="mt-10 md:mt-0">
								<h3 className="text-secondary-900 dark:text-secondary-100 text-sm leading-6 font-semibold">
									Socials
								</h3>
								<ul className="mt-6 space-y-4">
									{navigation.social.map((item) => (
										<li key={item.id}>
											<a
												href={item.href}
												className="hover:text-primary-500! text-sm leading-6 text-gray-400! dark:text-gray-400!"
												target="_blank"
												rel="noopener noreferrer">
												{item.name}
											</a>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="md:grid md:grid-cols-2 md:gap-8">
							<div>
								<h3 className="text-secondary-900 dark:text-secondary-100 text-sm leading-6 font-semibold">
									Links
								</h3>
								<ul className="mt-6 space-y-4">
									{navigation.links.map((item) => (
										<li key={item.name}>
											<a
												href={item.href}
												className="hover:text-primary-500! text-sm leading-6 text-gray-400! dark:text-gray-400!"
												target={item.target}
												rel="noopener noreferrer">
												{item.name}
											</a>
										</li>
									))}
								</ul>
							</div>

							<div className="mt-10 md:mt-0">
								<h3 className="text-secondary-900 dark:text-secondary-100 text-sm leading-6 font-semibold">
									Infos
								</h3>
								<ul className="mt-6 space-y-4">
									{navigation.legal.map((item) => (
										<li key={item.id}>
											<Link
												href={item.href}
												className="hover:text-primary-500! text-sm leading-6 text-gray-400! dark:text-gray-400!">
												{item.name}
											</Link>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="border-background-500 dark:border-dark-background-500 mt-16 mb-16 border-t pt-8 sm:mt-20 lg:mt-24 lg:mb-0">
					<p className="text-secondary-900 dark:text-secondary-100 text-xs leading-5">
						&copy; {currentYear} Foraum GmbH. Alle Rechte vorbehalten.
					</p>
				</div>
			</div>
		</footer>
	);
};

export default memo(BB_Footer);
