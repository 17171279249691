import React, { memo } from "react";

export type BB_MetaProps = {
	title: string;
	description?: string | null;

	url?: string | null;
	keywords: string;

	image?: string | null;
	imageWidth?: number | null;
	imageHeight?: number | null;
	imageAlt?: string;
};

const BB_Meta = (props: BB_MetaProps) => {
	return (
		<>
			{/* Generic */}
			<title>{props.title}</title>
			<meta property="og:locale" content="de_DE" />
			<meta name="title" content={props.title} />
			<meta name="author" content="Bestell.bar" />
			<meta name="keywords" content={props.keywords} />
			{props.url && <link rel="canonical" href={props.url} />}
			{props.description && <meta name="description" content={props.description} />}

			{/* Facebook / Meta */}
			<meta property="og:type" content="website" />
			<meta property="og:title" content={props.title} />
			<meta property="og:site_name" content="Bestell.bar" />
			{props.url && <meta property="og:url" content={props.url} />}
			{props.description && <meta property="og:description" content={props.description} />}
			{props.image && (
				<>
					<meta property="og:image" content={props.image} />
					<meta property="og:image:secure_url" content={props.image} />
					<meta property="og:image:width" content={props.imageWidth?.toString()} />
					<meta property="og:image:height" content={props.imageHeight?.toString()} />
					<meta property="og:image:alt" content={props.imageAlt} />
				</>
			)}

			{/* X / Twitter */}
			<meta
				property="twitter:card"
				content={props.image ? "summary_large_image" : "summary"}
			/>
			<meta property="twitter:title" content={props.title} />
			<meta name="twitter:site" content="@bestell_bar" />
			<meta name="twitter:creator" content="@bestell_bar" />
			{props.url && <meta property="twitter:url" content={props.url} />}
			{props.description && (
				<meta property="twitter:description" content={props.description} />
			)}
			{props.image && (
				<>
					<meta property="twitter:image" content={props.image} />
					<meta property="twitter:image:alt" content={props.imageAlt} />
				</>
			)}

			<script type="application/ld+json">
				{JSON.stringify({
					"@context": "https://schema.org",
					"@type": "WebSite",
					headline: "Bestell.bar",
					description: props.description,
					url: props.url,
					image: props.image
				})}
			</script>
		</>
	);
};

export default memo(BB_Meta);
